import { render, staticRenderFns } from "./u-tooltip-outlined-tile-lg.vue?vue&type=template&id=4b00f758&scoped=true"
import script from "./u-tooltip-outlined-tile-lg.vue?vue&type=script&lang=js"
export * from "./u-tooltip-outlined-tile-lg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b00f758",
  null
  
)

export default component.exports
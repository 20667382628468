import { render, staticRenderFns } from "./sub-heads.vue?vue&type=template&id=055a3f77&scoped=true"
import script from "./sub-heads.vue?vue&type=script&lang=js"
export * from "./sub-heads.vue?vue&type=script&lang=js"
import style0 from "./sub-heads.vue?vue&type=style&index=0&id=055a3f77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055a3f77",
  null
  
)

export default component.exports
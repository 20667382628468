import { render, staticRenderFns } from "./u-scrolldown-arrow.vue?vue&type=template&id=4f82902a&scoped=true"
import script from "./u-scrolldown-arrow.vue?vue&type=script&lang=js"
export * from "./u-scrolldown-arrow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f82902a",
  null
  
)

export default component.exports
<template>
    <u-tit-wrap-underline v-bind="$attrs">
        <u-tit-subtit v-if="this.$slots['TitAdd']" class="tit--xs"><slot name="TitAdd" /></u-tit-subtit>
        <u-tit-default class="tit--sm"><slot /></u-tit-default>
        <u-txt-default v-if="this.$slots['TxtAdd']" class="txt--sm"><slot name="TxtAdd" /></u-txt-default>
    </u-tit-wrap-underline>
</template>

<script>
import UTitWrapUnderline from "@/components/publish/styles/typography/u-tit-wrap-underline.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UTitWrapUnderline,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

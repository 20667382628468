import { render, staticRenderFns } from "./u-header-search-outlined-pill.vue?vue&type=template&id=0ebf1a22&scoped=true"
import script from "./u-header-search-outlined-pill.vue?vue&type=script&lang=js"
export * from "./u-header-search-outlined-pill.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ebf1a22",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./u-select-outlined-pill.vue?vue&type=template&id=247a1186&scoped=true"
import script from "./u-select-outlined-pill.vue?vue&type=script&lang=js"
export * from "./u-select-outlined-pill.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247a1186",
  null
  
)

export default component.exports
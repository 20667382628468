import { render, staticRenderFns } from "./u-pagination-elevated-rounded.vue?vue&type=template&id=18cead55&scoped=true"
import script from "./u-pagination-elevated-rounded.vue?vue&type=script&lang=js"
export * from "./u-pagination-elevated-rounded.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18cead55",
  null
  
)

export default component.exports
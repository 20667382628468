import { render, staticRenderFns } from "./u-select-outlined-rounded.vue?vue&type=template&id=3ae67d60&scoped=true"
import script from "./u-select-outlined-rounded.vue?vue&type=script&lang=js"
export * from "./u-select-outlined-rounded.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae67d60",
  null
  
)

export default component.exports